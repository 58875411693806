export const SET_ALL_INFORMATION = 'SET_ALL_INFORMATION';

export const setAllInformation = ({
  name,
  openHours,
  menuUrl,
  logoUrl,
  email,
  description,
  web,
  instagram,
  facebook,
  phone,
  openFirstImage,
  acceptVideos,
  theme,
  hasMenuSurvey,
  domain,
}) => ({
  type: SET_ALL_INFORMATION,
  payload: {
    name,
    openHours,
    menuUrl,
    logoUrl,
    email,
    description,
    web,
    instagram,
    facebook,
    phone,
    openFirstImage,
    acceptVideos,
    theme,
    hasMenuSurvey,
    domain,
  },
});
