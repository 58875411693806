import Swal from 'sweetalert2';
import { has } from 'lodash-es';
import { deleteAdminInfo } from '../redux/actions/adminActions';

const catchRequest = (err, dispatch, navigate) => {
  if (err?.message === 'Network Error') {
    Swal.fire(
      'Error de red',
      'Por favor verifique su conexión a internet',
      'error',
    );
  } else {
    if (has(err, 'message') && !err.message) return; // if request was canceled by user (axios cancelToken)
    if (err.response?.status === 401) {
      Swal.fire(
        'No Autorizado (401)',
        'error',
      );
      navigate('/products');
    } else if (err.response?.status === 403) {
      Swal.fire(
        'Sesión expirada',
        'Por favor vuelva a iniciar sesión',
        'info',
      ).then(() => {
        navigate('/login');
        dispatch(deleteAdminInfo());
        window.location.reload();
      });
    } else if (err.response?.status === 408) {
      Swal.fire(
        '¡La conexión tardó demasiado en responder!',
        'Actualize la página y revise su conexión a internet.',
        'error',
      );
    } else if (err.response?.status === 429) {
      Swal.fire(
        'Recurso bloqueado',
        'Este servicio está bloqueado a cierto número de solicitudes por periodo. ',
        'info',
      );
    } else {
      Swal.fire(
        'Error',
        'Ocurrió un error.',
        'error',
      );
    }
  }
};
export default catchRequest;
