import React, { lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { useLocation } from 'react-router-dom';
import useGetRequest from '../../hooks/useGetRequest';
import { setHeaderImages, setInitialValues, setPopupImage } from '../../redux/actions/menuActions';
import { setAcceptedLanguages, setReturnedLanguage } from '../../redux/actions/languagesActions';
import { setAllInformation } from '../../redux/actions/informationActions';
import languagesSelector from '../../redux/selectors/languagesSelector';
import { setInitialStyles } from '../../redux/actions/stylesActions';
import { useStateValue } from '../../providers/StateProvider';
import isFromAdmin from '../../helpers/general/isFromAdmin';
import LazyElement from '../../helpers/general/LazyElement';
import lazyRetry from '../../utils/lazyRetry';

const MenuThemesLazyContent = lazy(() => lazyRetry(() => import('./MenuThemesLazyContent'), 'MenuThemesLazyContent'));

function MenuThemesContainer() {
  const { currentLanguage } = useSelector(languagesSelector);
  const [{ triggerGetMenu }] = useStateValue();
  const location = useLocation();
  const isAdmin = isFromAdmin(location.pathname);

  const { data, loading } = useGetRequest(isAdmin ? '/menu/admins/menu/menu' : '/menu/users/users/menu', { language: currentLanguage }, [triggerGetMenu]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      dispatch(setInitialValues(data.products));
      dispatch(setAllInformation(data));
      dispatch(setAcceptedLanguages(data.acceptedLanguages));
      dispatch(setReturnedLanguage(data.selectedLanguage));
      dispatch(setHeaderImages(data.headerImages, data.openFirstImage));
      dispatch(setPopupImage(data.headerImages, data.openFirstImage));

      if (data.styles) {
        dispatch(setInitialStyles(data.styles));
      }
    }
  }, [loading]);

  if (loading) {
    return (
      <div className="d-flex vh-100 align-items-center justify-content-center">
        <Spin />
      </div>
    );
  }

  return (
    LazyElement(MenuThemesLazyContent)
  );
}

export default MenuThemesContainer;
