import {
  DELETE_ADMIN_INFO, SET_TOKEN, SET_ADMIN_INFO, TRIGGER_ACTION,
} from '../actions/adminActions';

const initialState = {
  id: null,
  user: null,
  userLvl: null,
  token: `Bearer ${localStorage.getItem('token')}`,
  isAdmin: false,
};

// eslint-disable-next-line default-param-last
const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN: {
      return {
        ...state,
        token: `Bearer ${action.payload}`,
      };
    }
    case SET_ADMIN_INFO: {
      return {
        ...state,
        id: action.payload.id,
        user: action.payload.user,
        userLvl: action.payload.userLvl,
        isAdmin: true,
      };
    }
    case DELETE_ADMIN_INFO: {
      return initialState;
    }

    case TRIGGER_ACTION: {
      return {
        ...state,
        [action.payload]: !state[action.payload],
      };
    }

    default:
      return state;
  }
};
export default adminReducer;
