const lazyRetry = (componentImport, name) => new Promise((resolve, reject) => {
  const hasRefreshed = JSON.parse(
    window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false',
  );
  componentImport().then((component) => {
    window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false');
    resolve(component);
  // eslint-disable-next-line consistent-return
  }).catch((error) => {
    if (!hasRefreshed) {
      window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true');
      return window.location.reload();
    }
    reject(error);
  });
});
export default lazyRetry;
