import React from 'react';
import './index.css';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import { StateProvider } from './providers/StateProvider';
import reducer, { initialState } from './providers/reducer';
import store from './redux/store';
import 'bootstrap/dist/css/bootstrap.css';

const container = document.getElementById('root');
const root = createRoot(container);
const queryClient = new QueryClient();

root.render(
  <Provider store={store}>
    <StateProvider initialState={initialState} reducer={reducer}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </StateProvider>
  </Provider>,
);
