import { SET_ALL_INFORMATION } from '../actions/informationActions';

const initialState = {
  name: null,
  openHours: null,
  menuUrl: null,
  logoUrl: null,
  email: null,
  description: null,
  web: null,
  domain: null,
  instagram: null,
  facebook: null,
  phone: null,
  openFirstImage: false,
  acceptVideos: false,
  hasMenuSurvey: false,
};

// eslint-disable-next-line default-param-last
const informationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_INFORMATION:
      return {
        ...state,
        name: action.payload.name,
        openHours: action.payload.openHours,
        menuUrl: action.payload.menuUrl,
        logoUrl: action.payload.logoUrl,
        email: action.payload.email,
        description: action.payload.description,
        web: action.payload.web,
        instagram: action.payload.instagram,
        facebook: action.payload.facebook,
        phone: action.payload.phone,
        openFirstImage: action.payload.openFirstImage,
        acceptVideos: action.payload.acceptVideos,
        theme: action.payload.theme,
        hasMenuSurvey: action.payload.hasMenuSurvey,
        domain: action.payload.domain,
      };

    default:
      return state;
  }
};

export default informationReducer;
