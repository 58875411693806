import { SET_ACCEPTED_LANGUAGES, SET_CURRENT_LANGUAGE, SET_RETURNED_LANGUAGE } from '../actions/languagesActions';

const initialState = {
  currentLanguage: navigator.language || navigator.languages[0],
  acceptedLanguages: [],
  returnedLanguage: null,
};

// eslint-disable-next-line default-param-last
const languagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.payload,
      };
    case SET_ACCEPTED_LANGUAGES:
      return {
        ...state,
        acceptedLanguages: action.payload,
      };
    case SET_RETURNED_LANGUAGE:
      return {
        ...state,
        returnedLanguage: action.payload,
      };
    default:
      return state;
  }
};

export default languagesReducer;
