import React from 'react';
import './index.css';
import { ConfigProvider } from 'antd';
import esES from 'antd/es/locale/es_ES';
import { useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import Router from './router/Router';
import stylesSelector from './redux/selectors/stylesSelector';
import useUserInfo from './hooks/useUserInfo';
import { darken, increaseBrightness } from './utils/modifyColors';
import useFontLoader from './hooks/useFontLoader';

const queryClient = new QueryClient();

function App() {
  const {
    colorPrimary, colorTextPrimary, colorTitles, backgroundColor, cardRadius, fontName,
  } = useSelector(stylesSelector);

  useUserInfo();
  useFontLoader();
  return (
    <ConfigProvider
      locale={esES}
      theme={{
        token: {
          fontFamily: fontName,
          colorPrimary,
          colorBgContainer: backgroundColor,
          colorBgElevated: backgroundColor,
          colorBorder: increaseBrightness(colorTextPrimary, 60),
          borderRadius: cardRadius,
          colorText: colorTextPrimary,
          colorTextDisabled: darken(colorTextPrimary, 30),
          colorTextPlaceholder: darken(colorTextPrimary, 40),
        },
        components: {
          Tabs: {
            itemColor: colorTitles,
            itemHoverColor: increaseBrightness(colorTitles, 10),
            itemSelectedColor: darken(colorTitles, 10),
            cardBg: darken(backgroundColor, 1),
            inkBarColor: darken(colorTitles, 10),
          },
          Button: {
            borderColorDisabled: darken(colorTextPrimary, 30),
            dangerShadow: null,
            primaryShadow: null,
          },
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        <Router />
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
