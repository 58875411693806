import {
  useState, useEffect, useRef, useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import catchRequest from '../utils/catchRequest';
import API from '../utils/api';
import adminSelector from '../redux/selectors/adminSelector';
/**
 * Hook personalizado para hacer una petición GET
 * @param {string} url - Endpoint a donde se hará la petición
 * @param {any{}} {params={}} - Params para url
 * @param {any[]} [dependencyArray=[]] - Al actualizarse una de estas variables; se volvera
 * a llamar la petición.
 *  * @param {boolean} [visible] - Si es falso no se llama la petición,
 * útil en componentes que inician ocultos
 * como un Modal o Drawer.
 */

const useGetRequest = (url, params = {}, dependencyArray = [], visible = true) => {
  const { token } = useSelector(adminSelector);
  const [state, setState] = useState({ data: [], loading: true });
  const isMounted = useRef(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const urlWithParams = useMemo(() => {
    let getUrl = url;
    Object.keys(params).forEach((param, index) => {
      getUrl = getUrl.concat(`${(index === 0) ? '?' : '&'}${param}=${params[param]}`);
    });
    return getUrl;
  }, [params, url]);

  useEffect(() => () => {
    isMounted.current = false;
  }, []);

  useEffect(() => {
    if (!visible) {
      return;
    }

    setState({ ...state, loading: true });

    API.get(urlWithParams, { headers: { Authorization: token } })
      .then(({ data }) => {
        // verifica que la pantalla que solicitó la peticion no se haya desmontado
        if (isMounted.current) {
          setState({
            loading: false,
            data: (data.ok)
              ? data.result
              : state.data,
          });
        }
      })
      .catch((err) => {
        setState({ ...state, loading: false });
        catchRequest(err, dispatch, navigate);
      });
  }, [urlWithParams, ...dependencyArray, visible]);

  return state;
};
export default useGetRequest;
