import {
  SET_FAVORITES_PRODUCTS, SET_HEADER_IMAGES, SET_INITIAL_VALUES, SET_POPUP_IMAGE,
} from '../actions/menuActions';

const initialState = {
  favoritesProducts: [],
  productsWithClass: {},
  classifications: [],
  headerImages: [],
  allProducts: [],
  popupImage: null,
  isMenuLoading: true,
};

// eslint-disable-next-line default-param-last
const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_VALUES:
      return {
        ...state,
        productsWithClass: action.payload.productsWithClass,
        classifications: action.payload.classifications,
        allProducts: action.payload.allProducts,
        popupImage: action.payload.popupImage,
        isMenuLoading: false,
      };
    case SET_HEADER_IMAGES:
      return {
        ...state,
        headerImages: action.payload,
      };
    case SET_FAVORITES_PRODUCTS:
      return {
        ...state,
        favoritesProducts: action.payload,
      };
    case SET_POPUP_IMAGE:
      return {
        ...state,
        popupImage: action.payload,
      };

    default:
      return state;
  }
};

export default menuReducer;
