import { lazy } from 'react';
import LazyElement from './LazyElement';
import lazyRetry from '../../utils/lazyRetry';

const LoginSuper = lazy(() => lazyRetry(() => import('../../pages/superadmin/LoginSuper'), 'LoginSuper'));

function handleSuperLoginRoute(userLvl, redirectTo) {
  const allowedHosts = ['localhost', 'menu.aodev.com.mx'];
  const isAllowedHost = allowedHosts.includes(window.location.hostname);

  if (!isAllowedHost) return redirectTo('/login');

  return userLvl === 1
    ? redirectTo('/super')
    : LazyElement(LoginSuper);
}
export default handleSuperLoginRoute;
