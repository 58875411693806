export const SET_INITIAL_STYLES = 'SET_INITIAL_STYLES';
export const SET_THEME_STYLES = 'SET_THEME_STYLES';
export const SET_BACKGROUND = 'SET_BACKGROUND';
export const SET_COLOR_CARD_TITLE = 'SET_COLOR_CARD_TITLE';
export const SET_COLOR_CARD_SUBTITLE = 'SET_COLOR_CARD_SUBTITLE';
export const SET_COLOR_TITLES = 'SET_COLOR_TITLES';
export const SET_COLOR_SUBTITLES = 'SET_COLOR_SUBTITLES';
export const SET_COLOR_PRIMARY = 'SET_COLOR_PRIMARY';
export const SET_COLOR_TEXT_PRIMARY = 'SET_COLOR_TEXT_PRIMARY';
export const SET_COLOR_SECONDARY = 'SET_COLOR_SECONDARY';
export const SET_COLOR_TEXT_SECONDARY = 'SET_COLOR_TEXT_SECONDARY';
export const SET_COLOR_PRICE_TEXT = 'SET_COLOR_PRICE_TEXT';
export const SET_CARD_BACKGROUND_COLOR = 'SET_CARD_BACKGROUND_COLOR';
export const SET_CARD_RADIUS = 'SET_CARD_RADIUS';
export const SET_CARD_CLASSIFICATION_TITLE = 'SET_CARD_CLASSIFICATION_TITLE';
export const SET_THEME = 'SET_THEME';
export const SET_CARD_CLASSIFICATION_BLUR = 'SET_CARD_CLASSIFICATION_BLUR';
export const SET_CARD_CLASSIFICATION_BRIGHTNESS = 'SET_CARD_CLASSIFICATION_BRIGHTNESS';
export const SET_FONT = 'SET_FONT';

export const setInitialStyles = (
  {
    colorPrimary,
    colorTextPrimary,
    colorSecondary,
    colorTextSecondary,
    colorPriceText,
    backgroundColor,
    cardBackgroundColor,
    cardRadius,
    font,
    colorTitles,
    colorSubtitles,
    colorCardTitle,
    colorCardSubtitle,
    theme,
    cardClassificationTitle,
    cardClassificationBlur,
    cardClassificationBrightness,
    fontName,
    fontLink,
    fontId,
  },
) => ({
  type: SET_INITIAL_STYLES,
  payload: {
    colorPrimary,
    colorTextPrimary,
    colorSecondary,
    colorTextSecondary,
    colorPriceText,
    backgroundColor,
    cardBackgroundColor,
    cardRadius,
    font,
    colorTitles,
    colorSubtitles,
    colorCardTitle,
    colorCardSubtitle,
    theme,
    cardClassificationTitle,
    cardClassificationBlur,
    cardClassificationBrightness,
    fontName,
    fontLink,
    fontId,
  },
});

export const setThemeStyles = (
  {
    colorPrimary,
    colorTextPrimary,
    colorSecondary,
    colorTextSecondary,
    colorPriceText,
    backgroundColor,
    cardBackgroundColor,
    cardRadius,
    font,
    colorTitles,
    colorSubtitles,
    colorCardTitle,
    colorCardSubtitle,
    cardClassificationTitle,
    cardClassificationBlur,
    cardClassificationBrightness,
    fontName,
    fontLink,
    fontId,
  },
) => ({
  type: SET_THEME_STYLES,
  payload: {
    colorPrimary,
    colorTextPrimary,
    colorSecondary,
    colorTextSecondary,
    colorPriceText,
    backgroundColor,
    cardBackgroundColor,
    cardRadius,
    font,
    colorTitles,
    colorSubtitles,
    colorCardTitle,
    colorCardSubtitle,
    cardClassificationTitle,
    cardClassificationBlur,
    cardClassificationBrightness,
    fontName,
    fontLink,
    fontId,
  },
});

export const setBackground = (backgroundColor) => ({
  type: SET_BACKGROUND,
  payload: backgroundColor,
});

export const setColorCardTitle = (colorCardTitle) => ({
  type: SET_COLOR_CARD_TITLE,
  payload: colorCardTitle,
});

export const setColorCardSubtitle = (colorCardSubtitle) => ({
  type: SET_COLOR_CARD_SUBTITLE,
  payload: colorCardSubtitle,
});

export const setColorTitles = (colorTitles) => ({
  type: SET_COLOR_TITLES,
  payload: colorTitles,
});

export const setColorSubtitles = (colorSubtitles) => ({
  type: SET_COLOR_SUBTITLES,
  payload: colorSubtitles,
});

export const setColorPrimary = (colorPrimary) => ({
  type: SET_COLOR_PRIMARY,
  payload: colorPrimary,
});

export const setColorTextPrimary = (colorTextPrimary) => ({
  type: SET_COLOR_TEXT_PRIMARY,
  payload: colorTextPrimary,
});

export const setColorSecondary = (colorSecondary) => ({
  type: SET_COLOR_SECONDARY,
  payload: colorSecondary,
});

export const setColorTextSecondary = (colorTextSecondary) => ({
  type: SET_COLOR_TEXT_SECONDARY,
  payload: colorTextSecondary,
});

export const setColorPriceText = (colorPriceText) => ({
  type: SET_COLOR_PRICE_TEXT,
  payload: colorPriceText,
});

export const setCardBackgroundColor = (cardBackgroundColor) => ({
  type: SET_CARD_BACKGROUND_COLOR,
  payload: cardBackgroundColor,
});

export const setCardRadius = (cardRadius) => ({
  type: SET_CARD_RADIUS,
  payload: cardRadius,
});

export const setCardClassificationTitle = (color) => ({
  type: SET_CARD_CLASSIFICATION_TITLE,
  payload: color,
});

export const setTheme = (theme) => ({
  type: SET_THEME,
  payload: theme,
});

export const setCardClassificationBlur = (value) => ({
  type: SET_CARD_CLASSIFICATION_BLUR,
  payload: value,
});

export const setCardClassificationBrightness = (percentaje) => ({
  type: SET_CARD_CLASSIFICATION_BRIGHTNESS,
  payload: percentaje,
});

export const setFont = (fontId, fontName, fontLink) => ({
  type: SET_FONT,
  payload: { fontId, fontName, fontLink },
});
