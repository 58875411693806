import React, { Suspense } from 'react';
import LazyLoading from '../../components/LazyLoading';

function LazyElement(Component) {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Component />
    </Suspense>
  );
}
export default LazyElement;
