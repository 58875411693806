import { has } from 'lodash-es';

export const SET_INITIAL_VALUES = 'SET_INITIAL_VALUES';
export const SET_HEADER_IMAGES = 'SET_HEADER_IMAGES';
export const SET_FAVORITES_PRODUCTS = 'SET_FAVORITES_PRODUCTS';
export const SET_POPUP_IMAGE = 'SET_POPUP_IMAGE';

export const setInitialValues = (productsString) => {
  const buildClassifications = {};
  let allProducts = [];
  try {
    allProducts = JSON.parse(productsString);
    allProducts?.forEach(({
      id,
      name,
      names,
      picture,
      thumbnail,
      idClassification,
      classification,
      idSubclassification,
      subclassification,
      orderClassification,
      orderSubclassification,
      description,
      descriptions,
      price,
      active,
      backgroundColor,
      backgroundImage,
    }) => {
      if (has(buildClassifications, classification)) {
        if (has(buildClassifications[classification].subclassifications, subclassification)) {
          buildClassifications[classification].subclassifications[subclassification].products.push({
            id,
            name,
            picture,
            thumbnail,
            description,
            descriptions,
            price,
            idSubClassification: idSubclassification,
            idClassification,
            active,
            names,

          });
        } else {
          buildClassifications[classification].subclassifications[subclassification] = {
            idSubclassification,
            order: orderSubclassification,
            products: [{
              id,
              name,
              picture,
              thumbnail,
              description,
              descriptions,
              price,
              idSubClassification: idSubclassification,
              idClassification,
              active,
              names,

            }],
          };
        }
      } else {
        buildClassifications[classification] = {
          order: orderClassification,
          idClassification,
          backgroundColor,
          backgroundImage,
          subclassifications: {
            [subclassification]: {
              idSubclassification,
              order: orderSubclassification,
              products: [{
                id,
                name,
                picture,
                thumbnail,
                description,
                descriptions,
                price,
                idSubClassification: idSubclassification,
                idClassification,
                active,
                names,

              }],
            },
          },
        };
      }
    });
  } catch (error) {
  // eslint-disable-next-line no-console
    console.log('error', error);
  }
  const classifications = [];
  Object.keys(buildClassifications).forEach((key) => {
    classifications.push({
      id: buildClassifications[key].idClassification,
      name: key,
      order: buildClassifications[key].order,
      subclassifications: buildClassifications[key].subclassifications,
      backgroundColor: buildClassifications[key].backgroundColor,
      backgroundImage: buildClassifications[key].backgroundImage,
    });
  });

  return ({
    type: SET_INITIAL_VALUES,
    payload: { productsWithClass: buildClassifications, classifications, allProducts },
  });
};

export const setHeaderImages = (headerImages, removeFirstImage) => {
  const images = JSON.parse(headerImages || '[]').sort((a, b) => (a.order > b.order ? 1 : -1));
  if (removeFirstImage && images.length) {
    images.shift();
  }
  return ({
    type: SET_HEADER_IMAGES,
    payload: images,
  });
};

export const setFavoriteProducts = (favoritesProducts) => ({
  type: SET_FAVORITES_PRODUCTS,
  payload: favoritesProducts,
});

export const setPopupImage = (headerImages, openFirstImage) => {
  const images = JSON.parse(headerImages || '[]').sort((a, b) => (a.order > b.order ? 1 : -1));
  let popupImage = null;
  if (openFirstImage && images.length) {
    [popupImage] = images;
    return ({
      type: SET_POPUP_IMAGE,
      payload: popupImage.picture,
    });
  }
  return ({
    type: SET_POPUP_IMAGE,
    payload: null,
  });
};
