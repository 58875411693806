export const initialState = {
  id: null,
  user: null,
  userLvl: null,
  token: `Bearer ${localStorage.getItem('token')}`,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TOKEN': {
      const token = action.item;
      localStorage.setItem('token', token);
      return {
        ...state,
        token: `Bearer ${token}`,
      };
    }

    case 'ADD_USER_INFO': {
      const {
        user, id, userLvl,
      } = action.item;
      return {
        ...state,
        id,
        user,
        userLvl,
      };
    }
    case 'DELETE_USER_INFO': {
      localStorage.clear();
      return initialState;
    }

    case 'TRIGGER_ACTION': {
      const KEY2 = `trigger${action.item}`;
      return {
        ...state,
        [KEY2]: !state[KEY2],
      };
    }

    default:
      return state;
  }
};
export default reducer;
