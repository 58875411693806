import React, { lazy } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MenuThemesContainer from '../containers/user/MenuThemesContainer';
import lazyRetry from '../utils/lazyRetry';
import adminSelector from '../redux/selectors/adminSelector';
import LazyElement from '../helpers/general/LazyElement';
import handleSuperLoginRoute from '../helpers/general/handleSuperLoginRoute';

const Login = lazy(() => lazyRetry(() => import('../pages/admin/Login'), 'Login'));
const HomeSuperAdmin = lazy(() => lazyRetry(() => import('../layouts/superadmin/HomeSuperAdmin'), 'HomeSuperAdmin'));
const MenuContainer = lazy(() => lazyRetry(() => import('../containers/admin/MenuContainer'), 'MenuContainer'));
const InformationContainer = lazy(() => lazyRetry(() => import('../containers/admin/InformationContainer'), 'InformationContainer'));
const PresentationContainer = lazy(() => lazyRetry(() => import('../containers/admin/PresentationContainer'), 'PresentationContainer'));
const ClassificationsContainer = lazy(() => lazyRetry(() => import('../containers/admin/ClassificationsContainer'), 'ClassificationsContainer'));
const LanguagesContainer = lazy(() => lazyRetry(() => import('../containers/admin/LanguagesContainer'), 'LanguagesContainer'));
const QRContainer = lazy(() => lazyRetry(() => import('../containers/admin/QRContainer'), 'QRContainer'));
const SurveysContainer = lazy(() => lazyRetry(() => import('../containers/admin/SurveysContainer'), 'SurveysContainer'));
const UserSurveysContainer = lazy(() => lazyRetry(() => import('../containers/user/UserSurveysContainer'), 'UserSurveysContainer'));
const AccountContainer = lazy(() => lazyRetry(() => import('../containers/admin/AccountContainer'), 'AccountContainer'));

function Router() {
  const { user, userLvl } = useSelector(adminSelector);

  const redirectTo = (path) => <Navigate replace to={path} />;

  const routes = [
    {
      path: '/login',
      element: user ? redirectTo('/admin/menu') : LazyElement(Login),
    },
    {
      path: '/admin',
      children: [
        {
          path: 'menu',
          children: [
            {
              path: ':selectedClassification',
              element: LazyElement(MenuContainer),
            },
            {
              path: '',
              element: LazyElement(MenuContainer),
            },
          ],
        },
        {
          path: 'generalInfo',
          element: user ? LazyElement(InformationContainer) : redirectTo('/login'),
        },
        {
          path: 'classifications',
          element: user ? LazyElement(ClassificationsContainer) : redirectTo('/login'),
        },
        {
          path: 'initialImages',
          element: user ? LazyElement(PresentationContainer) : redirectTo('/login'),
        },
        {
          path: 'languages',
          element: user ? LazyElement(LanguagesContainer) : redirectTo('/login'),
        },
        {
          path: 'qr',
          element: user ? LazyElement(QRContainer) : redirectTo('/login'),
        },
        {
          path: 'surveys',
          element: user ? LazyElement(SurveysContainer) : redirectTo('/login'),
        },
        {
          path: 'account',
          element: user ? LazyElement(AccountContainer) : redirectTo('/login'),
        },
      ],
    },
    {
      path: '/',
      children: [
        {
          path: ':selectedClassification',
          element: <MenuThemesContainer />,
        },
        {
          path: '',
          element: <MenuThemesContainer />,
        },
      ],
    },
    {
      path: '/surveys',
      children: [
        {
          path: ':surveyCode',
          element: LazyElement(UserSurveysContainer),
        },
        {
          path: '',
          element: redirectTo('/'),
        },
      ],
    },
    {
      path: '/superlogin',
      element: handleSuperLoginRoute(userLvl, redirectTo),
    },
    {
      path: '/super',
      element: userLvl !== 1 ? redirectTo('/superlogin') : LazyElement(HomeSuperAdmin),
    },
  ];

  const router = createBrowserRouter(routes);

  return (
    <RouterProvider router={router} />
  );
}

export default Router;
