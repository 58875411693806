import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import stylesSelector from '../redux/selectors/stylesSelector';
import loadFont from '../services/general/loadFont';

const useFontLoader = () => {
  const {
    fontLink,
  } = useSelector(stylesSelector);

  useEffect(() => {
    if (fontLink) {
      loadFont(fontLink);
    }
  }, [fontLink]);
};

export default useFontLoader;
