import axios from 'axios';

let baseURL;

if (process.env.REACT_APP_APP_ENV === 'localhost') {
  baseURL = 'http://localhost:3000/';
} else if (process.env.REACT_APP_APP_ENV === 'development') {
  baseURL = 'https://dev.api.aodev.com.mx/';
} else {
  baseURL = 'https://prod.api.aodev.com.mx/';
}

const API = axios.create({ baseURL });

export default API;
