import { DELETE_CURRENT_CLASSIFICATION, SET_CURRENT_CLASSIFICATION } from '../actions/currentClassificationActions';

const initialState = {
  id: null,
  name: null,
};

// eslint-disable-next-line default-param-last
const currentClassificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_CLASSIFICATION:
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
      };
    case DELETE_CURRENT_CLASSIFICATION:
      return {
        id: null,
        name: null,
      };

    default:
      return state;
  }
};

export default currentClassificationReducer;
