export const SET_TOKEN = 'SET_TOKEN';
export const SET_ADMIN_INFO = 'SET_ADMIN_INFO';
export const DELETE_ADMIN_INFO = 'DELETE_ADMIN_INFO';
export const TRIGGER_ACTION = 'TRIGGER_ACTION';

export const setToken = (token) => {
  localStorage.setItem('token', token);
  return ({
    type: SET_TOKEN,
    payload: token,
  });
};

export const setAdminInfo = ({ id, user, userLvl }) => ({
  type: SET_ADMIN_INFO,
  payload: { id, user, userLvl },
});

export const deleteAdminInfo = () => {
  localStorage.clear();
  return ({
    type: DELETE_ADMIN_INFO,
  });
};

export const triggerAction = (trigger) => {
  const KEY2 = `trigger${trigger}`;
  return {
    type: TRIGGER_ACTION,
    payload: KEY2,
  };
};
