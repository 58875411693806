/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
/* eslint-disable no-param-reassign */
const subtractLight = (color, amount) => {
  const cc = parseInt(color, 16) - amount;
  let c = (cc < 0) ? 0 : (cc);
  c = (c.toString(16).length > 1) ? c.toString(16) : `0${c.toString(16)}`;
  return c;
};

const darken = (color, amount) => {
  color = (color.indexOf('#') >= 0) ? color.substring(1, color.length) : color;
  amount = parseInt((255 * amount) / 100, 10);
  color = `#${subtractLight(color.substring(0, 2), amount)}${subtractLight(color.substring(2, 4), amount)}${subtractLight(color.substring(4, 6), amount)}`;
  return color;
};

const increaseBrightness = (hex, percent) => {
  hex = hex.replace(/^\s*#|\s*$/g, '');
  // eslint-disable-next-line eqeqeq
  if (hex.length == 3) {
    hex = hex.replace(/(.)/g, '$1$1');
  }
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  return `#${
    ((0 | (1 << 8) + r + (256 - r) * percent / 100).toString(16)).substr(1)
  }${((0 | (1 << 8) + g + (256 - g) * percent / 100).toString(16)).substr(1)
  }${((0 | (1 << 8) + b + (256 - b) * percent / 100).toString(16)).substr(1)}`;
};

export { darken, increaseBrightness };
