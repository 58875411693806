import { useEffect } from 'react';
import { decodeToken } from 'react-jwt';
import { useDispatch } from 'react-redux';
import { setAdminInfo } from '../redux/actions/adminActions';

const useUserInfo = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const { id, user, userLvl } = decodeToken(localStorage.getItem('token'));
      dispatch(setAdminInfo({
        id, token: localStorage.getItem('token'), user, userLvl,
      }));
    }
  }, []);
};
export default useUserInfo;
