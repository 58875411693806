import { combineReducers } from 'redux';
import stylesReducer from './stylesReducer';
import menuReducer from './menuReducer';
import languagesReducer from './languagesReducer';
import informationReducer from './informationReducer';
import currentClassificationReducer from './currentClassificationReducer';
import adminReducer from './adminReducer';

const rootReducer = combineReducers({
  styles: stylesReducer,
  menu: menuReducer,
  languages: languagesReducer,
  information: informationReducer,
  currentClassification: currentClassificationReducer,
  admin: adminReducer,

});

export default rootReducer;
