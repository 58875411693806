import themes from '../../utils/themes';
import {
  SET_BACKGROUND, SET_CARD_BACKGROUND_COLOR,
  SET_CARD_CLASSIFICATION_BLUR,
  SET_CARD_CLASSIFICATION_BRIGHTNESS,
  SET_CARD_CLASSIFICATION_TITLE,
  SET_CARD_RADIUS, SET_COLOR_CARD_SUBTITLE, SET_COLOR_CARD_TITLE, SET_COLOR_PRICE_TEXT,
  SET_COLOR_PRIMARY, SET_COLOR_SECONDARY, SET_COLOR_SUBTITLES, SET_COLOR_TEXT_PRIMARY,
  SET_COLOR_TEXT_SECONDARY, SET_COLOR_TITLES,
  SET_FONT,
  SET_INITIAL_STYLES,
  SET_THEME,
  SET_THEME_STYLES,
} from '../actions/stylesActions';

const initialState = {
  colorCardTitle: '#000000',
  colorCardSubtitle: '#000000',
  colorTitles: '#000000',
  colorSubtitles: '#000000',
  colorPrimary: '#BDBDBD', // #01579B
  colorTextPrimary: '#000000',
  colorSecondary: '#FF6F00',
  colorTextSecondary: '#ffffff',
  colorPriceText: '#33691E',
  backgroundColor: '#ffffff',
  cardBackgroundColor: '#ffffff',
  cardRadius: 10,
  font: null,
  theme: themes.LIST,
  cardClassificationTitle: '#ffffff',
  cardClassificationBlur: 2,
  cardClassificationBrightness: 20,
  fontName: null,
  fontLink: null,
  fontId: null,
};

// eslint-disable-next-line default-param-last
const stylesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_STYLES:
      return {
        ...state,
        colorTitles: action.payload.colorTitles,
        colorSubtitles: action.payload.colorSubtitles,
        colorPrimary: action.payload.colorPrimary,
        colorTextPrimary: action.payload.colorTextPrimary,
        colorSecondary: action.payload.colorSecondary,
        colorTextSecondary: action.payload.colorTextSecondary,
        colorPriceText: action.payload.colorPriceText,
        backgroundColor: action.payload.backgroundColor,
        cardBackgroundColor: action.payload.cardBackgroundColor,
        cardRadius: action.payload.cardRadius,
        font: action.payload.font,
        colorCardTitle: action.payload.colorCardTitle,
        colorCardSubtitle: action.payload.colorCardSubtitle,
        theme: action.payload.theme,
        cardClassificationTitle: action.payload.cardClassificationTitle,
        cardClassificationBlur: action.payload.cardClassificationBlur,
        cardClassificationBrightness: action.payload.cardClassificationBrightness,
        fontName: action.payload.fontName,
        fontLink: action.payload.fontLink,
        fontId: action.payload.fontId,
      };

    case SET_THEME_STYLES:
      return {
        ...state,
        colorTitles: action.payload.colorTitles,
        colorSubtitles: action.payload.colorSubtitles,
        colorPrimary: action.payload.colorPrimary,
        colorTextPrimary: action.payload.colorTextPrimary,
        colorSecondary: action.payload.colorSecondary,
        colorTextSecondary: action.payload.colorTextSecondary,
        colorPriceText: action.payload.colorPriceText,
        backgroundColor: action.payload.backgroundColor,
        cardBackgroundColor: action.payload.cardBackgroundColor,
        cardRadius: action.payload.cardRadius,
        font: action.payload.font,
        colorCardTitle: action.payload.colorCardTitle,
        colorCardSubtitle: action.payload.colorCardSubtitle,
        cardClassificationTitle: action.payload.cardClassificationTitle,
        cardClassificationBlur: action.payload.cardClassificationBlur,
        cardClassificationBrightness: action.payload.cardClassificationBrightness,
        fontName: action.payload.fontName,
        fontLink: action.payload.fontLink,
        fontId: action.payload.fontId,
      };
    case SET_BACKGROUND:
      return {
        ...state,
        backgroundColor: action.payload,
      };
    case SET_COLOR_CARD_TITLE:
      return {
        ...state,
        colorCardTitle: action.payload,
      };
    case SET_COLOR_CARD_SUBTITLE:
      return {
        ...state,
        colorCardSubtitle: action.payload,
      };
    case SET_COLOR_TITLES:
      return {
        ...state,
        colorTitles: action.payload,
      };
    case SET_COLOR_SUBTITLES:
      return {
        ...state,
        colorSubtitles: action.payload,
      };
    case SET_COLOR_PRIMARY:
      return {
        ...state,
        colorPrimary: action.payload,
      };
    case SET_COLOR_TEXT_PRIMARY:
      return {
        ...state,
        colorTextPrimary: action.payload,
      };
    case SET_COLOR_SECONDARY:
      return {
        ...state,
        colorSecondary: action.payload,
      };
    case SET_COLOR_TEXT_SECONDARY:
      return {
        ...state,
        colorTextSecondary: action.payload,
      };
    case SET_COLOR_PRICE_TEXT:
      return {
        ...state,
        colorPriceText: action.payload,
      };
    case SET_CARD_BACKGROUND_COLOR:
      return {
        ...state,
        cardBackgroundColor: action.payload,
      };
    case SET_CARD_RADIUS:
      return {
        ...state,
        cardRadius: action.payload,
      };
    case SET_CARD_CLASSIFICATION_TITLE:
      return {
        ...state,
        cardClassificationTitle: action.payload,
      };
    case SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case SET_CARD_CLASSIFICATION_BLUR:
      return {
        ...state,
        cardClassificationBlur: action.payload,
      };
    case SET_CARD_CLASSIFICATION_BRIGHTNESS:
      return {
        ...state,
        cardClassificationBrightness: action.payload,
      };
    case SET_FONT:
      return {
        ...state,
        fontName: action.payload.fontName,
        fontLink: action.payload.fontLink,
        fontId: action.payload.fontId,
      };
    default:
      return state;
  }
};

export default stylesReducer;
